var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "goods-return-order-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "客户订单",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('div', {
    staticClass: "goods-return-order-content"
  }, [_c('div', {
    staticClass: "goods-return-order-cell-container"
  }, [_c('van-checkbox-group', {
    ref: "checkboxGroup",
    model: {
      value: _vm.selectedOrders,
      callback: function callback($$v) {
        _vm.selectedOrders = $$v;
      },
      expression: "selectedOrders"
    }
  }, _vm._l(_vm.orders, function (order, index) {
    return _c('van-cell', {
      key: index,
      staticClass: "goods-return-order-cell",
      attrs: {
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toggle(index);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('van-checkbox', {
            ref: "checkboxes",
            refInFor: true,
            attrs: {
              "name": order.id
            }
          }, [_vm._v(" " + _vm._s(order.num) + " ")])];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1)])]), _c('div', {
    staticClass: "fixed-bottom-btn goods-return-order-footer"
  }, [_c('van-checkbox', {
    on: {
      "click": _vm.checkAll
    },
    model: {
      value: _vm.checkedAll,
      callback: function callback($$v) {
        _vm.checkedAll = $$v;
      },
      expression: "checkedAll"
    }
  }, [_vm._v("全选")]), _c('div', {
    staticClass: "goods-return-order-footer__selected"
  }, [_c('span', {
    staticClass: "goods-return-order-footer__tips"
  }, [_vm._v("已选：" + _vm._s(_vm.selectedOrders.length))]), _c('van-button', {
    attrs: {
      "disabled": _vm.selectedOrders.length === 0,
      "round": ""
    },
    on: {
      "click": _vm.handleCancelOrder
    }
  }, [_vm._v("取消订单")])], 1)], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.reasons
    },
    on: {
      "select": _vm.cancelOrder
    },
    model: {
      value: _vm.showReasonView,
      callback: function callback($$v) {
        _vm.showReasonView = $$v;
      },
      expression: "showReasonView"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }