<template>
  <div class="goods-return-order-container">
    <my-nav-bar
      title="客户订单"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <div class="goods-return-order-content">
        <div class="goods-return-order-cell-container">
          <van-checkbox-group ref="checkboxGroup" v-model="selectedOrders">
            <van-cell
              v-for="(order, index) in orders"
              :key="index"
              class="goods-return-order-cell"
              center
              @click="toggle(index)"
            >
              <template #title>
                <van-checkbox
                  ref="checkboxes"
                  :name="order.id"
                >
                  {{ order.num }}
                </van-checkbox>
              </template>
            </van-cell>
          </van-checkbox-group>
        </div>
      </div>
    </div>
    <div class="fixed-bottom-btn goods-return-order-footer">
      <van-checkbox v-model="checkedAll" @click="checkAll">全选</van-checkbox>
      <div class="goods-return-order-footer__selected">
        <span class="goods-return-order-footer__tips">已选：{{ selectedOrders.length }}</span>
        <van-button :disabled="selectedOrders.length === 0" round @click="handleCancelOrder">取消订单</van-button>
      </div>
    </div>
    <van-action-sheet v-model="showReasonView" :actions="reasons" @select="cancelOrder" />
  </div>
</template>

<script>
import goodsReturnApi from '@/api/goods_return'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'GoodsReturnOrder',
  components: { myNavBar },
  data() {
    return {
      orders: [],
      selectedOrders: [],
      checkedAll: false,
      showReasonView: false,
      reasons: [
        { type: 1, name: '客户取消' },
        { type: 2, name: '没上到货' },
        { type: 4, name: '开错单' },
        { type: 5, name: '上错货' }
      ]
    }
  },
  watch: {
    selectedOrders(newVal) {
      this.checkedAll = newVal.length === this.orders.length
    }
  },
  created() {
    this.getOrders()
  },
  methods: {
    getOrders() {
      const params = {
        client_id: this.$route.query.client_id,
        bill_reject_id: this.$route.query.bill_reject_id
      }
      goodsReturnApi.order(params).then(res => {
        this.orders = res.data
      })
    },
    toggle(index) {
      const checkbox = this.$refs.checkboxes[index]
      checkbox.toggle()
    },
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(this.checkedAll)
    },
    handleCancelOrder() {
      this.showReasonView = true
    },
    cancelOrder(action) {
      const data = {
        order_ids: this.selectedOrders,
        after_order_id: this.$route.query.after_order_id,
        client_id: this.$route.query.client_id,
        remark_type: action.type,
        bill_reject_id: this.$route.query.bill_reject_id
      }
      goodsReturnApi.cancelOrder(data).then(res => {
        this.success(res.msg)
        this.$router.go(-1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .goods-return-order-content {
    padding: 15px 15px 5px 15px;
    .goods-return-order-cell-container {
      padding-bottom: 10px;
      .goods-return-order-cell {
        height: 50px;
      }
    }
  }
  .goods-return-order-footer {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    &__selected {
      .van-button {
        height: 35px;
      }
      display: flex;
      align-items: center;
    }
    &__tips {
      margin-right: 10px;
      color: #969799;
    }
  }
</style>
